textarea.form-control {
  border: 1px solid lighten($black, 5%);
  border-radius: $border-radius-lg;
  &:focus,
  &:active {
    border: 1px solid lighten($primary, 5%);
  }
}
.form-group:not(.has-danger):not(.has-success),
.input-group-focus:not(.has-danger):not(.has-success) {
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    border-color: $danger;
  }
  .form-control {
    &:focus {
      border-color: $danger;
      & + .input-group-append .input-group-text,
      & ~ .input-group-append .input-group-text,
      & + .input-group-prepend .input-group-text,
      & ~ .input-group-prepend .input-group-text {
        border: 1px solid $danger;
      }
    }
  }
}
