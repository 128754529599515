.ReactTable .rt-noData {
    color: rgba(255, 255, 255, 0.7) !important;
    background: none !important;
}

.ReactTable.-clickable .rt-tbody .rt-tr {
    cursor: pointer;
}

.summary-table .table > tbody > tr:last-of-type td {
    font-weight: bold;
}