.modal-dialog .table > thead > tr > th,
.modal-dialog .table > tbody > tr > td,
.modal-dialog .form-control {
    color: #2b3553 !important;
}

    .modal-dialog .form-control:focus {
        color: #2b3553;
    }

.spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}